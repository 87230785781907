import React from 'react'
import SbEditable from 'storyblok-react'
import makeStyles from '@material-ui/styles/makeStyles'
import { Box } from '@material-ui/core'
import renderBloks from '@renderBloks'
import { H1, H4, H6 } from '@system'
import useStringTranslation from '@hooks/use-string-translation'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingTop: '69px',
    paddingBottom: '69px',
  },
  image: {
    width: '100%',
    height: '100%',
    maxHeight: '622px',
    maxWidth: '693px',
  },
  title: {
    fontWeight: theme.typography.fontWeightExtraBold,
    color: theme.palette.primary.main,
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
  },
  right: {},
  description: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.tertiary,
  },
  linksContainer: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.tertiary,
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacer: {
    padding: '10%',
  },
}))

const PageNotFound = (props) => {
  const classes = useStyles(props)
  const { description, title, links, image } = props.blok
  return (
    <SbEditable content={props.blok}>
      <Box className={classes.root}>
        <Box className={classes.left}>
          <Box>
            <H1 className={classes.title}>{title}</H1>
            <H4 className={classes.description}>{description}</H4>
          </Box>
          <Box className={classes.spacer} />
          <Box className={classes.linksContainer}>
            <H6>
              {useStringTranslation('Here are some helpful links instead')}:
            </H6>
            <Box className={classes.links}>{renderBloks(links)}</Box>
          </Box>
        </Box>
        <Box className={classes.right}>
          <img className={classes.image} src={image.filename} />
        </Box>
      </Box>
    </SbEditable>
  )
}

export default PageNotFound
